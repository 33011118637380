import navigation from './navigation.module.css';
import {Link} from "react-router-dom";
function Header({sidebarToggle = () => {}}) {
    return (
        <div className={navigation.header}>
            <div className={navigation.flexContainer}>
                <Link to={'/'} className={"hlWithoutStyle"}>
                    <span className={`${navigation.title} marginLeft30`}>
                        한백중학교 3학년 1반
                    </span>
                </Link>
            </div>
            <div className={navigation.flexContainer}>
                <span className={`${navigation.materialIcons} marginRight30`} onClick={sidebarToggle}>
                    list
                </span>
            </div>
        </div>
    )
}
export default Header
