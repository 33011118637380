import main from "../main.module.css";
import navigation from './navigation.module.css';
function Footer() {
    return (
        <footer className={navigation.footer}>
            <div className={`${main.textContainer} marginLeft10`}>
                <span className={navigation.semiTitle}>Class 3-1</span>
                <span className={navigation.mainText}>오픈 소스 라이센스</span>
                <span className={navigation.mainText}>이용 약관</span>
                <span className={navigation.mainText}>개인정보 수집 및 처리 방침</span>
                <span className={navigation.mainText}>&copy; Ryu Siwoo 2022</span>
                <span className={navigation.mainText}>삽입된 미디어는 해당 저작권자가 저작권을 소유합니다.</span>
            </div>
            <div className={`${main.textContainer} marginLeft30`}>
                <span className={navigation.semiTitle}>Contact</span>
                <span className={navigation.mainText}>thezoot3@gmail.com</span>
                <span className={navigation.mainText}>hanbaek.s200157@ggm.goe.go.kr</span>
            </div>
        </footer>
    )
}
export default Footer;
