
export const CHANGE_GRADE = "calculator/testSelector/CHANGE_GRADE";
export const CHANGE_SEMESTER = "calculator/testSelector/CHANGE_SEMESTER";
export const CHANGE_TEST = "calculator/testSelector/CHANGE_TEST";
export const CHANGE_SUBJECT = "calculator/testSelector/CHANGE_SUBJECT"
export const CHANGE_CURRENT = "calculator/testSelector/CHANGE_CURRENT";
export const CHANGE_LAST_CHANGED = "calculator/testSelector/CHANGE_LAST_CHANGED"
export const changeGrade = (grade=1) => {
    return {
        "type": CHANGE_GRADE,
        grade: grade
    }
}
export const changeSemester = (semester=0) => {
    return {
        "type": CHANGE_SEMESTER,
        semester: semester
    }
}
export const changeTest = (test=0) => {
    return {
        "type": CHANGE_TEST,
        test: test
    }
}
export const changeSubject = (subject=0) => {
    return {
        "type": CHANGE_SUBJECT,
        subject: subject
    }
}
export const changeCurrent = (ctype, values) => {
    let v = values;
    if(values === '@@init') {
        v = initState.current[ctype]
    }
    return {
        "type": CHANGE_CURRENT,
        ctype: ctype, //어떤거 바꿀지 쓰는거
        values: v
    }
}
export const changeLastChanged = (ctype) => {
    return {
        "type": CHANGE_LAST_CHANGED,
        ctype: ctype,
    }
}
export const initState = {
    "grade": -1,
    "semester": null,
    "testType": null,
    "subject": null,
    "current": {semester: [], testType: [], subject: [], testData: {}}
}
export const reducer = (state=initState, action) => {
    switch (action.type) {
        case CHANGE_GRADE:
            return {...state, grade: action.grade, lastChanged: "grade"}
        case CHANGE_SEMESTER:
            return {...state, semester: action.semester, lastChanged: "semester"}
        case CHANGE_TEST:
            return {...state, testType: action.test, lastChanged: "testType"}
        case CHANGE_SUBJECT:
            return {...state, subject: action.subject, lastChanged: "subject"}
        case CHANGE_CURRENT:
            if(action.ctype === "testData" || action.values.findIndex(i => {return i === state[action.ctype]}) > -1) {
                return {...state, current: {...state.current, [action.ctype]: action.values}, lastChanged: null}
            }
            return {...state, current: {...state.current, [action.ctype]: action.values},[action.ctype]: -1, lastChanged: null}
        case CHANGE_LAST_CHANGED:
            return {...state, lastChanged: action.ctype}
        default:
            return state;
    }
}
