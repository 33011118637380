import main from "./main.module.css";
import calculator from './calculator/calculator.module.css';
import TestSelector from "./calculator/TestSelector";
import RankDisplay from "./calculator/RankDisplay";
import {connect} from 'react-redux';
function Calculator({selecState, userState}) {
    return (
            <div className={main.root}>
                <div className={`${main.mainContainer} ${main.textContainer}`}>
                    <span className={`${main.title}`}>
                        석차 계산기
                    </span>
                    <span className={main.semiTitle}>
                        2020년도 입학생 기준 한백중학교 지필평가에 대해서 석차를 계산할 수 있습니다!
                    </span>
                    {selecState.current.testData.totalStud ?
                        <RankDisplay/> :
                        (
                            <div className={`${calculator.calUnselBox} ${main.mainContainer}`}>
                                <span className={main.buttonIcon}>login</span>
                                <span className={main.mainText}>평가 정보를 정확히 입력하세요</span>
                            </div>
                        )
                    }
                </div>
                <div className={`${main.mainContainer} ${main.textContainer}`}>
                    <span className={main.semiTitle}>
                        평가 정보를 입력하세요
                    </span>
                    <TestSelector/>
                </div>
            </div>
    )
}
function mapStateToProps(state) {
    return {selecState: state.testSelector, userState: state.userInput}
}
export default connect(mapStateToProps)(Calculator);
