import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { initState as testSelectorI, reducer as testSelectorR } from './testSelector';
import { initState as userInputI, reducer as userInputR} from "./userInput";
//import { initState as rankDisplayI, reducer as rankDisplayR } from "./rankDisplay";
const initialState = {
    "testSelector": testSelectorI,
    "userInput": userInputI,
    //"rankDisplay": rankDisplayI
}
const reducer = (state= initialState, action) => {
    let path = action.type.split('/');
    switch (path[1]) {
        case "testSelector":
            return {...state, testSelector: testSelectorR(state.testSelector,action)}
        case "userInput":
            return {...state, userInput: userInputR(state.userInput,action)}
        //case "rankDisplay":
          //  return {...state, rankDisplay: rankDisplayR(state.rankDisplay, action)}
        default:
            return state;
    }
}
const composer = composeWithDevTools({trace: true, traceLimit: 25});
export const store = createStore(reducer,composer());
