import image from "./image.module.css";
import {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import formatList from '../../static/format.json';
function ImageDisplayer({src, description, timestamp, classMap, alt}) {
    const [classNames, setNames] = useState({});
    const classList = [
        'container',
        'imageDesc',
        'image',
        'imageDescIcon',
        'imageDescC',
        'imageDescText',
        'imageDescTitle',
        'videoContainer',
        'videoIcon'
    ]
    const splitedSrc = src.split(".");
    const format = splitedSrc[splitedSrc.length - 1];
    const isVideo = formatList.video.includes(format);
    useEffect(()=>{
        let classObject = {};
        classList.forEach(i => {
            classObject[i] = classMap ? classMap.get(i) + " " + image[i] : image[i]
        })
        setNames(classObject)
    },[classMap])
    return (
        <div className={classNames.container}>
            {isVideo ?
                <div className={classNames.videoContainer}>
                    <video src={src} className={classNames.image} controls autoPlay={true} loop={true} />
                    <span className={classNames.videoIcon + " materialIcon"}>play_arrow</span>
                </div>
                 :
                <img className={classNames.image} src={src} alt={alt}/>
            }
            <div className={classNames.imageDesc}>
                <div className={classNames.imageDescC}>
                    <span className={"materialIcon " + classNames.imageDescIcon}>fullscreen</span>
                </div>
                <div className={classNames.imageDescC}>
                    <span className={classNames.imageDescTitle}>{description}</span>
                    {timestamp ? <span className={classNames.imageDescText}>{timestamp.getFullYear()}-{timestamp.getMonth()+1}-{timestamp.getDate() + 1}</span>
                        : null }
                </div>
            </div>
        </div>
    )
}
ImageDisplayer.propTypes = {
    src: PropTypes.string.isRequired,
    description: PropTypes.string,
    timestamp: PropTypes.object,
    classMap: PropTypes.object,
    alt: PropTypes.string
}
ImageDisplayer.defaultProps = {
    alt: "image",
}
export default ImageDisplayer
