import noticeList from './notice.module.css';
import '../../index.css';
import {NoticeTitle} from "./notice";
function NoticeList() {
    return (
        <div className={noticeList.root}>
            <NoticeTitle title={"공지사항"}/>
            <div className={noticeList.listRoot}>
                <div className={noticeList.listElement}>
                    <span className={noticeList.leTitle}>공지사항입니다.</span>
                    <span className={noticeList.leComment}>[1]</span>
                </div>
            </div>
        </div>
    )
}
export default NoticeList
