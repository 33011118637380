import {connect} from 'react-redux';
import calculator from './calculator.module.css'
import {useEffect} from "react";
import axios from "axios";
import {changeGrade, changeSemester, changeTest, changeSubject, changeCurrent, changeLastChanged } from "../../redux/calculator/testSelector";
import {changeOLScore} from '../../redux/calculator/userInput';
export const subjects = ["국어", "사회", "도덕", "역사", "수학", "과학", "기술과 가정", "정보", "영어", "한문", "일본어"];
const testType = ["중간고사", "기말고사"];
const getType = ["grade", "semester", "testType", "subject", "testData"]
function TestSelector({selecState, userState, dispatch}) {
    const chgGrade = e => {
        dispatch(changeGrade(e.target.value))
    }
    const chgSem = e => {
        dispatch(changeSemester(e.target.value))
    }
    const chgTest = e => {
        dispatch(changeTest(e.target.value))
    }
    const chgSubj = e => {
        dispatch(changeSubject(e.target.value))
    }
    const chgOLScore = e => {
        if(e.target.value > 100 || e.target.value < 0) {
            alert("원점수는 0과 100사이의 실수만 입력할 수 있습니다.")
            return e.preventDefault();
        }
        dispatch(changeOLScore(e.target.value))
    }
    useEffect(()=>{
        async function loadData() {
            let removed = false;
            let lastChanged = selecState.lastChanged;
            let type = getType?.[getType.findIndex(i => {return selecState.lastChanged === i}) + 1];
            getType.slice(getType.findIndex(i => {return type === i}), getType.length).forEach(i => { //상위 계층이 변경되었을 때 하위 계층 초기화
                if(i !== selecState.lastChanged) {
                    if(selecState.current[i].length > 0) {
                        dispatch(changeCurrent(i, '@@init'))
                        removed = true;
                    } else if(selecState.current[i].constructor === Object && selecState.current[i] !== {}) {
                        dispatch(changeCurrent(i, '@@init'))
                    }

                }
            })
            if(removed) return dispatch(changeLastChanged(lastChanged)); //초기화 후 재실행
            let queryString = "?";
            for(let i in selecState) {
                if(typeof selecState[i] === "string" && i !== "lastChanged") {
                    queryString += `${i}=${selecState[i]}&`
                }
            }
            let data = (await axios.get(`http://localhost:3002/api/test${queryString}`, {
                headers: {"isContainsAmount": type === "testData"}
            })).data;
            let map = new Map();
            if(type !== "testData") {
                for(let i of data) {
                    if(!map.get(i[type])) {
                        map.set(i[type], true);
                    }
                }
            } else {
                return dispatch(changeCurrent("testData", {"totalStud": data[0].totalStud, "totalAvg": data[0].totalAvg, "standardDev": data[0].standardDev}));
            }
            let keys = [];
            map.forEach((v, k) => {keys.push(k)})
            dispatch(changeCurrent(type, keys))
        }
        if(selecState.grade !== -1 && selecState.lastChanged) loadData()
    },[selecState])
    return (
        <div className={calculator.TSRoot}>
            <div className={calculator.TSContainer}>
                <div className={calculator.TSElement}>
                    <select className={calculator.TSInput} value={selecState.grade} onChange={chgGrade}>
                        <option value={-1}> </option>
                        <option value={0} disabled>1학년</option>
                        <option value={1}>2학년</option>
                        <option value={2}>3학년</option>
                    </select>
                </div>
                <div className={calculator.TSElement}>
                    <select className={calculator.TSInput} value={selecState.semester} onChange={chgSem}>
                        <option value={-1}> </option>
                        {selecState.current.semester?.map((i,index)=>{
                            return <option value={index} key={index}>{index + 1}학기</option>
                        })}
                    </select>
                </div>
                <div className={calculator.TSElement}>
                    <select className={calculator.TSInput} value={selecState.testType} onChange={chgTest}>
                        <option value={-1}> </option>
                        {selecState.current.testType?.map(i=>{
                            return <option value={i} key={i}>{testType[i]}</option>
                        })}
                    </select>
                </div>
                <div className={calculator.TSElement}>
                    <select className={calculator.TSInput} value={selecState.subject} onChange={chgSubj}>
                        <option value={-1}> </option>
                        {selecState.current.subject?.map(i=>{
                            return <option value={i} key={i}>{subjects[i]}</option>
                        })}
                    </select>
                </div>
            </div>
            {selecState.current.testData.totalStud ? (
                <div className={calculator.TSInfoBox}>
                    <span className={calculator.TSInfo}>응시인원 {selecState.current.testData.totalStud}명</span>
                    <span className={calculator.TSInfo}>전체평균 {selecState.current.testData.totalAvg}점</span>
                    <span className={calculator.TSInfo}>표준편차 {selecState.current.testData.standardDev}점</span>
                    <div className={calculator.TSOLScoreBox}>
                        <span className={calculator.TSInfo}>당신의 원점수</span>
                        <input className={calculator.TSOLScore} value={userState.OLScore} onChange={chgOLScore} type={'number'}/>
                        <span className={calculator.TSInfo}>점</span>
                    </div>
                </div>
                ) : null}
            <div className={calculator.TSAmountInfoContainer}>
                <div className={calculator.TSAmountInfoTextC}>
                    <span className={calculator.TSAmountTitle}>응시인원</span>
                    <span className={calculator.TSAmountInfo}>실제로 해당 과목 시험을 응시 한 학생의 수를 의미한다.</span>
                </div>
                <div className={calculator.TSAmountInfoTextC}>
                    <span className={calculator.TSAmountTitle}>전체평균</span>
                    <span className={calculator.TSAmountInfo}>해당 과목을 응시한 학생의 점수의 평균값을 의미한다.</span>
                </div>
                <div className={calculator.TSAmountInfoTextC}>
                    <span className={calculator.TSAmountTitle}>표준편차</span>
                    <span className={calculator.TSAmountInfo}>해당 과목을 응시한 학생의 점수의 분포도를 의미한다.</span>
                </div>
                <div className={calculator.TSAmountInfoTextC}>
                    <span className={calculator.TSAmountTitle}>원점수</span>
                    <span className={calculator.TSAmountInfo}>해당 과목에서 학생이 시험에서 실제로 받은 점수를 의미하며 100점 만점을 기준으로한다.</span>
                </div>
            </div>
        </div>
    )
}
//TODO 112라인 소숫점 작성 고치기
//<span>{new Date().toLocaleDateString()}</span>
function mapStateToProps(state) {
    return {selecState: state.testSelector, userState: state.userInput}
}
function mapDispatchToProps(dispatch) {
    return {dispatch}
}
export default connect(mapStateToProps, mapDispatchToProps)(TestSelector)
