import Header from "./Header";
import Sidebar from "./Sidebar";
import {useState} from "react";
import navigation from './navigation.module.css'
function Navigation() {
    const [sidebar, setSidebar] = useState(false);
    return (
        <div className={navigation.root}>
            <Header sidebarToggle={() => {setSidebar((current) => !current)}}/>
            {sidebar ? <Sidebar/> : null}
        </div>
    )
}
export default Navigation;
