import {NoticeTitle} from './notice';
import notice from "./notice.module.css";
const difference = +4000
function ClassMoney({detailed = false}) {
    return (
        <div className={notice.root}>
            <NoticeTitle title={"학급비"} materialIcon={"receipt_long"}/>
            <div className={notice.listRoot}>
                <div className={notice.ntElement}>
                    <span className={notice.ntBigText}>82,000원</span>
                    <span className={notice.ntText} style={{color: difference > 0 ? "red" : difference !== 0 ? "blue" : null}}>({difference.toLocaleString()})</span>
                </div>
            </div>
        </div>
    )
}
export default ClassMoney
