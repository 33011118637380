import main from './main.module.css'
import {useEffect} from "react";
import NoticeList from "./notice/NoticeList";
import ImageContainer from "./image/ImageContainer";
import ClassMoney from "./notice/ClassMoney";
const fn = ["라", "다", "나", "가"]
const n = ["라", "다", "나", "가"]
const nameNum = 30;
function Home() {
    let names = [];
    for(let i = 0; i < nameNum; i ++) {
        let name = [];
        name[0] = fn[Math.floor(Math.random() * fn.length)]
        name[1] = n[Math.floor(Math.random() * n.length)]
        name[2] = n[Math.floor(Math.random() * n.length)]
        names.push(name.join(""));
    }
    return (
        <div className={main.root}>
            <div className={main.container}>
                <div className={`${main.mainContainer} ${main.textContainer}`}>
                    <span className={`${main.title}`}>
                        서로 배려하며 함께 성장하는 1반
                    </span>
                        <span className={main.semiTitle}>
                        Grow with helping and considering
                    </span>
                </div>
                <div className={main.nameCanvas}>
                    {names.map(i => {
                        return <div className={main.name}>{i}</div>
                    })}
                </div>
                <div className={main.noticeContainer}>
                    <NoticeList/>
                    <ClassMoney/>
                </div>
            </div>
            <div>
                <ImageContainer/>
            </div>
        </div>
    )
}

export default Home
