import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider} from 'react-redux';
import React from "react";
import Navigation from "./component/navigation/Navigation";
import Footer from "./component/navigation/Footer";
import main from './component/main.module.css';
import Caculator from "./component/Calculator";
import {store as caculatorStore} from './redux/calculator/calculator';
import Home from "./component/Home";
function App() {
    return (
        <BrowserRouter>
            <div id={main.App}>
                <Navigation/>
                <Provider store={caculatorStore}>
                    <Routes>
                        <Route path={"/calculator"} element={<Caculator/>}/>
                        <Route path={"/"} element={<Home/>}/>
                    </Routes>
                </Provider>
                <Footer/>
            </div>
        </BrowserRouter>

    )
}

export default App;
