
export const CHANGE_OLSCORE = "calculator/userInput/CHANGE_OLSCORE";
export const CHANGE_GRAPHSTYLE = "calculator/userInput/CHANGE_GRAPHSTYLE";
export const CHANGE_COLORS = "calculator/userInput/CHANGE_COLORS";
export const changeOLScore = (score=0) => {
    return {
        "type": CHANGE_OLSCORE,
        score
    }
}
export const graphStyle = ["rowStyle", "columnStyle", "pyramidStyle"];
export const changeGraphStyle = (style = 0) => {
    return {
        "type": CHANGE_GRAPHSTYLE,
        style
    }
}
export const colorPreset = [
    {

    }
]
export const initState = {
    "OLScore": 0,
    "graphStyle": graphStyle[0],
}
export const reducer = (state=initState, action) => {
    switch (action.type) {
        case CHANGE_OLSCORE:
            return {...state, OLScore: action.score}
        case CHANGE_GRAPHSTYLE:
            if(graphStyle[action.style]) return {...state, style: action.style};
            break;
        default:
            return state;
    }
}
