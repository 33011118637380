import imageContainer from './image.module.css';
import image from '../../image/sonic-floss.gif';
import image2 from '../../static/file_example_WEBM_640_1_4MB.webm';
import '../../index.css';
import ImageDisplayer from "./ImageDisplayer";
function ImageContainer() {
    return (
        <div className={imageContainer.root}>
            <ImageDisplayer src={image}/>
            <ImageDisplayer src={image2}/>
        </div>
    )
}
export default ImageContainer
