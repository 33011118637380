import notice from "./notice.module.css";
import PropTypes from "prop-types";

function NoticeTitle({title, materialIcon, viewMore}) {
    return (
        <div className={notice.topbar}>
            <span className={notice.ntTitle}>{title}</span>
            <div className={notice.tbViewMore}>
                <span className={"materialIcon"}>{materialIcon}</span>
                <span className={notice.tbViewMoreText}>{viewMore}</span>
            </div>
        </div>
    )
}
NoticeTitle.defaultProps = {
    title: "Notice",
    materialIcon: "more",
    viewMore: "더보기"
}
NoticeTitle.propTypes = {
    title: PropTypes.string,
    materialIcon: PropTypes.string,
    viewMore: PropTypes.string,
}
export {NoticeTitle}
