import {connect} from 'react-redux';
import calculator from './calculator.module.css'
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useEffect, useState} from "react";
import gaussian from "gaussian";
import chartBack from '../../image/chart.png';
import {subjects} from './TestSelector';
import {images as rankImages} from '../../image/emoji/emoji';
Chart.register(...registerables);
const labels = [];
for(let i = 0; i < 101; i++) {
    labels.push(i)
}
const option = {
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
    },
    animations: {
        "1": false

    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            title: {
                display: true,
                text: "원점수"
            }
        },
        y: {
            title: {
                display: true,
                text: "백분율"
            }
        }
    },
    plugins: {
        legend: {
            labels: {
                font: {
                    family: "'Noto Sans KR', 'sans-serif'",
                }
            }
        }
    }
}
function RankDisplay({selecState, userState, dispatch}) {
    const [rank, setRank] = useState({});
    const [data, setData] = useState({
        labels: labels,
        datasets: [
            {
                label: "당신의 석차",
                data: [],
                backgroundColor: "#f0260f",
                borderWidth: 0,
                pointRadius: 5,
                pointHoverRadius: 10,
            },
            {
                label: "정규분포곡선",
                data: [],
                backgroundColor: "#83dcb7",
                borderColor: "#83dcb7",
                borderJoinStyle: "round",
                pointRadius: 0,
                borderWidth: 3,
                pointHitRadius: 3,
                pointStyle: "dash",
            }
        ]
    })
    useEffect(()=>{
        let gs = gaussian(selecState.current.testData.totalAvg, Math.pow(selecState.current.testData.standardDev, 2), selecState.current.testData.standardDev);
        let data = labels.map(i => {
            return (0.5 - Math.abs(0.5 - gs.cdf(i))) * 100;
        })
        let userData = []
        for(let i = 0; i < userState.OLScore; i++) {
            userData.push();
        }
        userData[userState.OLScore] = data[userState.OLScore];
        let rank = data[userState.OLScore];
        let currentStud;
        let lessThanMe;
        let betterThanMe
        if(userState.OLScore === '100') {
            currentStud = Math.round(data[userState.OLScore] / 100 * selecState.current.testData.totalStud)
            lessThanMe = selecState.current.testData.totalStud - currentStud;
            betterThanMe = 0;
        } else {
            if(userState.OLScore < selecState.current.testData.totalAvg) {
                rank = (50 - data[userState.OLScore]) + 50;
            }
            //TODO 나보다 잘한놈, 못한놈 수식 점검 필요
            currentStud = Math.round(Math.abs(data[parseInt(userState.OLScore) + 1] - data[userState.OLScore]) / 100 * selecState.current.testData.totalStud);
            lessThanMe = Math.round(data[parseInt(userState.OLScore) - 1] / 100 * selecState.current.testData.totalStud);
            betterThanMe = Math.round(rank / 100 * selecState.current.testData.totalStud);
        }
        setRank({rank, currentStud, lessThanMe, betterThanMe});
        let image = new Image(20, 20);
        image.src = rankImages[`rank_to${Math.ceil((100-rank)/10)*10}`]
        setData(()=>{
            return {
                labels: labels,
                datasets: [
                    {
                        label: "당신의 석차",
                        data: userData,
                        backgroundColor: "#f0260f",
                        borderColor: "#f0260f",
                        borderWidth: 3,
                        pointRadius: 3,
                        pointHoverRadius: 5,
                        pointStyle: image,
                    },
                    {
                        label: "정규분포곡선",
                        data: data,
                        backgroundColor: "#83dcb7",
                        borderColor: "#83dcb7",
                        borderJoinStyle: "round",
                        pointRadius: 0,
                        borderWidth: 3,
                        pointHitRadius: 3,
                        pointStyle: "dash",
                    },

                ]
            }
        })
    }, [selecState, userState]);
    return (
        <div className={`${userState.OLScore ? "" : calculator.RankInfoContainerExtended} ${calculator.RankInfoContainer}`}>
            <div className={calculator.RankChartContainer}>
                <Line data={data} options={option} />
            </div>
            {userState.OLScore ?
                <div className={`${calculator.RankInfoBox}`}>
                <span className={calculator.RankInfoText}>
                    당신의<span className={calculator.RankInfoTextHighlight}> {subjects[selecState.subject]}과 </span>성적은
                    상위<span className={calculator.RankInfoTextHighlight}> {Math.round(rank.rank)}%</span>에 속합니다.
                </span>
                    <span className={calculator.RankInfoText}>
                    같은 점수를 받은 학생은 <span className={calculator.RankInfoTextHighlight}> {rank.currentStud}</span>명입니다.
                </span>
                    <span className={calculator.RankInfoText}>
                    대략<span className={calculator.RankInfoTextHighlight}> {rank.lessThanMe}</span>명보다 성적이 좋습니다.
                </span>
                    {userState.OLScore < 100 ?
                        <span className={calculator.RankInfoText}>
                        대략<span className={calculator.RankInfoTextHighlight}> {rank.betterThanMe}</span>명보다는 성적이 낮습니다.
                    </span>
                        : null
                    }
                </div> : <div/>
            }
            <span className={calculator.RankInfoSmall}>
                <span className={calculator.RankInfoText}>&#8251; </span>
                이 수치는 가우스 분포에 기반하여 산출된 결과이며,
                정밀하게 계산되지 않았기 때문에 실제 값과 오차가 존재할 수 있고
                이 값에 대하여 운영자는 보증을 하지 않으며. 따라서 단순 참고용으로만 사용하기를 권장합니다.
            </span>
        </div>
    )
}

function mapStateToProps(state) {
    return {selecState: state.testSelector, userState: state.userInput}
}
function mapDispatchToProps(dispatch) {
    return {dispatch}
}
export default connect(mapStateToProps, mapDispatchToProps)(RankDisplay)
