import rank_to10 from './rank_to10.png';
import rank_to20 from './rank_to20.png';
import rank_to30 from './rank_to30.png';
import rank_to40 from './rank_to40.png';
import rank_to50 from './rank_to50.png';
import rank_to60 from './rank_to60.png';
import rank_to70 from './rank_to70.png';
import rank_to80 from './rank_to80.png';
import rank_to90 from './rank_to90.png';
import rank_to100 from './rank_to100.png';

export const images = {rank_to10, rank_to20, rank_to30, rank_to40, rank_to50, rank_to60, rank_to70, rank_to80, rank_to90, rank_to100}
